<template>
  <div class="auth" :class="{ 'has-notify': !!getAuthNotification }">
    <AuthNotification v-if="!!getAuthNotification" />
    <div class="container">
      <AuthForm />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthForm from '@/components/Auth/AuthForm.vue';
import AuthNotification from '@/elements/AuthNotification.vue';

export default {
  components: {
    AuthForm,
    AuthNotification,
  },
  destroyed() {
    if (!!this.getAuthNotification)
      this.$store.commit('CLEAR_AUTH_NOTIFICATION');
  },
  computed: {
    ...mapGetters(['getAuthNotification']),
  },
};
</script>

<style lang="sass" scoped>
.auth
  padding-top: 120px
  @include ms
    padding-top: 0
</style>

<template>
  <form class="auth-form" novalidate @submit.prevent @keyup.enter.prevent>
    <h1 class="auth-form__title">Sign in</h1>
    <div class="auth-form__subtitle text">
      Please input your information in the fields below to enter your account
    </div>
    <div class="auth-form__items">
      <div class="auth-form__item">
        <LabelField id="email" class="auth-form__label" title="E-Mail" />
        <TextField
          id="email"
          v-model.trim="email"
          name="email"
          required
          class="auth-form__input"
          type="email"
          :error="isEmailError"
          :error-txt="emailErrorText"
          tooltip-text="Enter your e-mail address"
          @focus="resetServerError('email')"
        />
      </div>
      <div class="auth-form__item">
        <LabelField id="password" class="auth-form__label" title="Password" />
        <TextField
          id="password"
          v-model.trim="password"
          name="password"
          required
          class="auth-form__input"
          type="password"
          is-password
          :password-strength="passwordStrength"
          :error="isPasswordError"
          :error-txt="passwordErrorText"
          tooltip-text="Password must be at least <span>8 characters</span> with <span>1 upper case letter</span> and <span>1 number</span>."
          @focus="resetServerError('password')"
        />
      </div>
    </div>
    <div class="auth-form__controls">
      <ButtonBase :loading="loading" size="max" @click="authHandler"
        >Sign in</ButtonBase
      >
      <button class="auth-form__caption" @click.prevent="goToForgotPassword">
        Forgot your password?
      </button>
    </div>
  </form>
</template>

<script>
import authValidation from '@/mixins/validation/forms/auth.js';
import LabelField from '@/elements/LabelField';

export default {
  components: {
    LabelField,
  },
  mixins: [authValidation],
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  created() {
    if (!!this.$route.params.email) this.email = this.$route.params.email;
  },
  methods: {
    async authHandler() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.loading = true;
      await this.$store.dispatch('authorization', {
        email: this.email,
        password: this.password,
      });
      this.loading = false;
    },
    goToForgotPassword() {
      this.$router.push({
        name: 'forgot-password',
        params: {
          email: this.email,
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-form__subtitle
  margin-top: 12px
  margin-bottom: 64px
  @include ms
    margin-top: 8px
    margin-bottom: 40px

.auth-form__items
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 24px 40px
  text-align: left
  @include l
    gap: 24px
  @include ms
    grid-template-columns: 1fr

.auth-form__caption
  display: inline-block
  margin-top: 20px
  color: $primary-color-blue
  @include adaptive-font-size(14, 14, 12)
  @include adaptive-line-height(21, 21, 18)
  font-weight: 600
  @include ms
    margin-top: 0
    margin-bottom: 24px

.auth-form__controls
  display: flex
  flex-direction: column
  margin-top: 52px
  @include ms
    flex-direction: column-reverse
    margin-top: 24px
</style>

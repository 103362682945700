<template>
  <div class="auth-notification">
    <div class="auth-notification__content">
      <div class="auth-notification__icon">
        <svg-icon name="info_message"></svg-icon>
      </div>
      <div class="auth-notification__info">
        <div class="auth-notification__text">
          {{ getAuthNotification.text }}
        </div>
      </div>
    </div>
    <button class="auth-notification__close" @click="close">
      <svg-icon name="cross_white"></svg-icon>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getAuthNotification']),
  },
  methods: {
    close() {
      this.$store.commit('CLEAR_AUTH_NOTIFICATION');
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-notification
  width: 768px
  margin: 0 auto
  display: flex
  align-items: center
  justify-content: space-between
  padding: 20px
  background-color: $primary-color-blue
  border-radius: 8px 8px 0px 0px
  @include l
    width: 688px
  @include m
    width: 570px
  @include ms
    width: 100%
    position: fixed
    bottom: 0
    padding: 15px
    border-radius: 0

.auth-notification__content
  display: flex
  align-items: center

.auth-notification__icon,
.auth-notification__close
  display: flex

.auth-notification__icon svg
  max-height: 16px
  max-width: 16px

.auth-notification__text
  font-size: 14px
  line-height: 21px
  color: $white
  margin-left: 12px
  @include ms
    font-size: 12px

.auth-notification__close svg
  max-height: 14px
  max-width: 14px
</style>

import {
  required,
  minLength,
  helpers,
  not,
  sameAs,
} from 'vuelidate/lib/validators';

const isHasUppercaseLetter = helpers.regex('isHasUppercaseLetter', /[A-Z]+/);
const isHasNumber = helpers.regex('isHasNumber', /\d+/);

export default {
  validations: {
    password: {
      required,
      minLength: minLength(8),
      isHasUppercaseLetter,
      isHasNumber,
      notOldPassword: not(sameAs('oldPassword')),
    },
  },

  computed: {
    isPasswordError() {
      return (
        (this.$v.password.$dirty && this.$v.password.$invalid) ||
        !!this.getErrors.password ||
        !!this.getErrors.new_password
      );
    },
    passwordErrorText() {
      if (!this.$v.password.required) {
        return 'The field is required';
      }
      if (!this.$v.password.notOldPassword) {
        return `New password couldn't match with old password`;
      }
      if (!this.$v.password.minLength) {
        return 'Password must be at least 8 characters';
      }
      if (!this.$v.password.isHasUppercaseLetter) {
        return 'Password must be at least with 1 upper case letter';
      }
      if (!this.$v.password.isHasNumber) {
        return 'Password must be at least with 1 number';
      }
      if (!!this.getErrors.password) {
        return this.getErrors.password[0];
      }
      if (!!this.getErrors.new_password) {
        return this.getErrors.new_password[0];
      }
    },
    passwordStrength() {
      return {
        minLength: this.$v.password.required && this.$v.password.minLength,
        isHasUppercaseLetter:
          this.$v.password.required && this.$v.password.isHasUppercaseLetter,
        isHasNumber: this.$v.password.required && this.$v.password.isHasNumber,
      };
    },
  },
};
